// config.js

const getEnvironment = () => {
  const hostname = window.location.hostname;
  if (hostname === "proprompt.store") {
    return "production";
  } else {
    return "development";
  }
};

const config = {
  development: {
    apiBaseURL: "https://raw-api.proprompt.store",
    cacheURL: "https://api.proprompt.store",
    env: "development",
  },
  production: {
    apiBaseURL: "https://raw-api.proprompt.store",
    cacheURL: "https://api.proprompt.store",
    env: "production",
  },
};

// Function to return the appropriate API endpoint based on the environment
const returnAPI = () => {
  const env = getEnvironment();
  return config[env].apiBaseURL;
};

const returnCacheURL = () => {
  const env = getEnvironment();
  return config[env].cacheURL;
};
export { returnAPI, getEnvironment, returnCacheURL };
