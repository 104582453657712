import React, { useState, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";

import "react-tooltip/dist/react-tooltip.css";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
  Box,
  FormControl,
  InputLabel,
  Input,
  IconButton,
  Popover,
  Button,
  TextField,
} from "@mui/material";
import { IoIosFolder, IoIosFolderOpen } from "react-icons/io";
import {
  MdOutlineFolderCopy,
  MdOutlineDriveFolderUpload,
} from "react-icons/md";
import {
  GrActions,
  GrEmoji,
  GrFan,
  GrProjects,
  GrTarget,
  GrTest,
  GrPieChart,
} from "react-icons/gr";
import { GiAnchor } from "react-icons/gi";
import { FaPalette } from "react-icons/fa";

import "./AddFolder.css";
import { DataContext } from "./DataContext"; // Import DataContext
import { generateIdFromName } from "./lib";
import Image from "./Image";

const EditFolder = (props) => {
  const { openStatus, promptPopupContent = null } = props;

  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [notes, setNotes] = useState(""); // State for notes
  const [paletteColor, setPaletteColor] = useState("#9da19e");
  const [folderIcon, setFolderIcon] = useState(<IoIosFolder />);
  const [anchorElTitle, setAnchorElTitle] = useState(null);
  const [anchorElPalette, setAnchorElPalette] = useState(null);
  const [uploadedImage, setUploadedImage] = useState(null);
  const { parentId } = useParams();
  const { handleUpdate, handleAdd } = useContext(DataContext); // Access the context

  const icons = [
    { component: <IoIosFolder />, name: "IoIosFolder" },
    { component: <IoIosFolderOpen />, name: "IoIosFolderOpen" },
    { component: <MdOutlineFolderCopy />, name: "MdOutlineFolderCopy" },
    {
      component: <MdOutlineDriveFolderUpload />,
      name: "MdOutlineDriveFolderUpload",
    },
    { component: <GrActions />, name: "GrActions" },
    { component: <GrEmoji />, name: "GrEmoji" },
    { component: <GrFan />, name: "GrFan" },
    { component: <GrProjects />, name: "GrProjects" },
    { component: <GrTarget />, name: "GrTarget" },
    { component: <GrTest />, name: "GrTest" },
    { component: <GrPieChart />, name: "GrPieChart" },
    { component: <GiAnchor />, name: "GiAnchor" },
  ];

  const colors = [
    "#f44336",
    "#e91e63",
    "#9c27b0",
    "#673ab7",
    "#3f51b5",
    "#2196f3",
    "#03a9f4",
    "#00bcd4",
    "#009688",
    "#4caf50",
    "#8bc34a",
    "#cddc39",
    "#ffeb3b",
    "#ffc107",
    "#ff9800",
    "#ff5722",
    "#795548",
    "#607d8b",
  ];
  useEffect(() => {
    if (promptPopupContent) {
      console.log(promptPopupContent.placeholders, "testingkit");
      setTitle(promptPopupContent.name || "");
      setNotes(promptPopupContent.notes || "");
      setPaletteColor(promptPopupContent.color || "#9da19e");
      const icon = icons.find((icon) => icon.name === promptPopupContent.icon);
      setFolderIcon(
        icon ? (
          icon.component
        ) : promptPopupContent?.icon.startsWith("https://") ? (
          promptPopupContent.icon
        ) : (
          <IoIosFolder />
        )
      );
    }
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (openStatus) setOpen(true);
    else setOpen(false);
  }, [openStatus]);

  const handleClose = () => {
    setOpen(false);
    props.handleClose();
  };
  const handleSubmit = async () => {
    const folderIconName =
      icons.find((icon) => icon.component.type === folderIcon.type)?.name ||
      folderIcon;
    const newCollection = {
      name: title,
      notes: notes, // Include notes in the new collection object
      color: paletteColor,
      icon: uploadedImage || folderIconName,
      parentId: parentId || null,
      id: promptPopupContent
        ? promptPopupContent.id
        : generateIdFromName(title),
    };

    try {
      if (promptPopupContent) {
        await handleUpdate(newCollection);
      } else {
        await handleAdd(newCollection);
      }
      handleClose(); // Close the dialog box after saving
    } catch (error) {
      console.error("Error saving collection:", error);
    }
  };

  const handlePaletteTitleClick = (event) => {
    setAnchorElTitle(event.currentTarget);
  };

  const handlePaletteClick = (event) => {
    setAnchorElPalette(event.currentTarget);
  };

  const handleIconSelect = (icon) => {
    console.log("Selected Icon:", icon.name); // Log selected icon
    setFolderIcon(icon.component);
    setAnchorElTitle(null);
  };

  const handleColorSelect = (color) => {
    console.log("Selected Color:", color); // Log selected color
    setPaletteColor(color);
    setAnchorElPalette(null);
  };

  const handleImageUpload = async (event) => {
    const files = event.target.files;
    if (files.length > 0) {
      const file = files[0];
      const formData = new FormData();
      formData.append("file", file);
      formData.append("upload_preset", "tiwecp6e"); // Use environment variables

      try {
        const response = await fetch(
          `https://api.cloudinary.com/v1_1/Lensbird/image/upload`,
          {
            method: "POST",
            body: formData,
          }
        );
        if (!response.ok) {
          throw new Error("Failed to upload image");
        }
        const data = await response.json();
        setUploadedImage(data.secure_url);

        setFolderIcon(data.secure_url);
        setAnchorElTitle(null);
      } catch (error) {
        console.error("Error uploading image:", error);
      }
    }
  };

  console.log(anchorElTitle, "testingkit");
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            width: "700px",
            maxWidth: "none",
          },
        }}
      >
        <DialogTitle>Create Folder</DialogTitle>
        <DialogContent>
          <Box sx={{ "& > :not(style)": { m: 1 } }}>
            <FormControl variant="standard" fullWidth>
              <InputLabel htmlFor="input-with-icon-adornment">
                Folder Name
              </InputLabel>
              <Input
                id="input-with-icon-adornment"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                startAdornment={
                  <InputAdornment position="start">
                    {typeof folderIcon === "string" &&
                    folderIcon?.startsWith("https://") ? (
                      <Image
                        onClick={handlePaletteTitleClick}
                        src={folderIcon}
                      />
                    ) : (
                      <IconButton
                        onClick={handlePaletteTitleClick}
                        size="small"
                      >
                        {folderIcon}
                      </IconButton>
                    )}
                  </InputAdornment>
                }
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton onClick={handlePaletteClick} size="small">
                      <FaPalette
                        style={{ color: paletteColor, fontSize: "20px" }}
                      />
                    </IconButton>
                  </InputAdornment>
                }
              />
              <Popover
                open={Boolean(anchorElTitle)}
                anchorEl={anchorElTitle}
                onClose={() => setAnchorElTitle(null)}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
              >
                <Box sx={{ display: "flex", justifyContent: "center", mt: 3 }}>
                  <Button variant="contained" component="label">
                    Upload Image
                    <input type="file" hidden onChange={handleImageUpload} />
                  </Button>
                </Box>
                <Box sx={{ display: "flex", flexWrap: "wrap", p: 1, gap: 0 }}>
                  {icons.map((icon, index) => (
                    <IconButton
                      key={index}
                      onClick={() => handleIconSelect(icon)}
                      style={{
                        margin: "4px",
                        borderRadius: "8px",
                        padding: "4px",
                        width: "48px",
                        height: "48px",
                      }}
                    >
                      {icon.component}
                    </IconButton>
                  ))}
                </Box>
              </Popover>
              <Popover
                open={Boolean(anchorElPalette)}
                anchorEl={anchorElPalette}
                onClose={() => setAnchorElPalette(null)}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
              >
                <Box sx={{ display: "flex", flexWrap: "wrap", p: 1, gap: 0 }}>
                  {colors.map((color, index) => (
                    <IconButton
                      key={index}
                      onClick={() => handleColorSelect(color)}
                      style={{
                        backgroundColor: color,
                        margin: "4px",
                        borderRadius: "8px",
                        padding: "4px",
                        width: "48px",
                        height: "48px",
                      }}
                    ></IconButton>
                  ))}
                </Box>
              </Popover>
            </FormControl>
            <TextField
              label="Notes"
              multiline
              fullWidth
              rows={4}
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button
            onClick={handleSubmit}
            color="primary"
            variant="contained"
            disabled={!title}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default EditFolder;
