import React, { useContext } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Main from "./Main";
import "./App.css";
import { DataProvider } from "./DataContext";
import { UserProvider, UserContext } from "./UserContext"; // Import the UserProvider and UserContext
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const getBasename = () => {
  const path = window.location.pathname.split("/");
  if (path[1] === "master") {
    return "/master";
  } else if (path[1] === "prompts") {
    return "/prompts";
  } else {
    return "/";
  }
};

// Create a ProtectedRoute component
const ProtectedRoute = ({ children, roleRequired }) => {
  let { user = {}, loading } = useContext(UserContext);
  console.log(roleRequired, user, "mest");

  if (loading) {
    return <div></div>; // You can replace this with a loading spinner or component
  }

  if (!user) {
    user = { role: "user" };
  }
  if (roleRequired && user?.role !== roleRequired && !user.role === "admin") {
    return <Navigate to="/" />; // Redirect to home if not authorized
  }

  return <DataProvider>{children}</DataProvider>;
};

function App() {
  return (
    <Router basename={getBasename()}>
      <UserProvider>
        <div className="App">
          <Routes>
            <Route
              path="/my-prompts"
              element={
                <DataProvider>
                  <Main />
                </DataProvider>
              }
            />
            <Route
              path="/collections"
              element={
                <DataProvider>
                  <Main />
                </DataProvider>
              }
            />
            <Route
              path="/collections/:parentId"
              element={
                <ProtectedRoute roleRequired="user">
                  <Main />
                </ProtectedRoute>
              }
            />
            {/* Protected Route for /master, accessible only to admin */}
            <Route
              path="/"
              element={
                <ProtectedRoute
                  roleRequired={getBasename() === "/master" ? "admin" : "user"}
                >
                  <Main />
                </ProtectedRoute>
              }
            />
          </Routes>
        </div>
        <ToastContainer />
      </UserProvider>
    </Router>
  );
}

export default App;
