import React from "react";
import { CgFolderAdd } from "react-icons/cg";
import "react-tooltip/dist/react-tooltip.css";

import "./AddFolder.css";
import EditFolder from "./EditFolder";

const AddFolder = () => {
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <button
        className="add-folder-button"
        onClick={handleClickOpen}
        data-tooltip-id="addFolderTooltip"
        data-tooltip-content="Add Folder"
      >
        <CgFolderAdd />
      </button>

      <EditFolder openStatus={open} handleClose={handleClose} />
    </div>
  );
};

export default AddFolder;
