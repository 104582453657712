import React, { useState, useContext, useEffect, useMemo } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import "./Main.css";
import SearchBar from "./SearchBar";
import TabNavigation from "./TabNavigation";
import AddPrompt from "./AddPrompt";
import AddFolder from "./AddFolder";
import { DataContext } from "./DataContext";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Item from "./Item";
import PromptPopup from "./PromptPopup";
import "./PromptList.css";
import { ReactComponent as BackButton } from "./svg/BackButton.svg";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import EditPrompt from "./EditPrompt";
import EditFolder from "./EditFolder";
import { CircularProgress } from "@mui/material";
import { UserContext } from "./UserContext";
import Fuse from "fuse.js";
import PurgeCache from "./PurgeCache";
import FilterTags from "./FilterTags";
import SelectionBar from "./SelectionBar";
import CopyPasteDialog from "./CopyPasteDialog.jsx";

const Main = (props) => {
  const { user, token } = useContext(UserContext); // Access loading state

  const [searchTerm, setSearchTerm] = useState("");
  const [promptPopupStatus, setPromptPopupStatus] = useState(null);
  const [folderPopupStatus, setFolderPopupStatus] = useState(null);
  const [copyPasteDialogOpen, setCopyPasteDialogOpen] = useState(false);

  const [selectedItems, setSelectedItems] = useState(new Set());
  const [itemPopupContent, setItemPopupContent] = useState(null);

  const [loading, setLoading] = useState(true);
  const { parentId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const {
    data: { collections, prompts, allCollections, allPrompts },
    fetchingAdditionalPrompts,
    origin,
    handleUpdateOrder,
    handleExport,
    fetchCollectionsByParentId,
    masterParentId,
    handleStarItem,
    curatedCollections,
    fetchingCuratedCollections,
    selectedCuratedCategory,
    selectCuratedCategory,
    handleBulkDuplicate,
  } = useContext(DataContext);
  console.log(prompts, "testingjoe1");
  const MySwal = withReactContent(Swal);

  useEffect(() => {
    if (user?.role !== "admin" && origin === "master") {
      window.location.assign("/prompts");
    }
  }, [user, origin]);
  useEffect(() => {
    if (
      (collections?.length > 0 || prompts?.length > 0) &&
      !fetchingAdditionalPrompts
    ) {
      setLoading(false); // Set loading to false when data is loaded
    }
  }, [collections, prompts]);
  // Optimized filterCollections function with fuzzy search
  const filterCollections = (collections, term, allCollections, parentId) => {
    console.log(collections, allCollections, term, "testingjoe3");

    if (!term || !allCollections) {
      return collections;
    }

    console.log("testingjoe33", parentId);

    const fuseOptions = {
      keys: ["name"],
      // Lower threshold means stricter matching, higher means more lenient
      threshold: 0.3, // Changed from dynamic threshold to fixed value
      // Add these new options
      minMatchCharLength: 2, // Match at least 2 characters
      distance: 100, // Allow more characters between matches
      ignoreLocation: true, // Don't consider character location in scoring
      useExtendedSearch: true, // Enable extended search features
      shouldSort: true,
      sortFn: (a, b) => {
        // Helper function to check if any of the matches are exact
        const hasExactMatch = (item) => {
          return item.matches.some((match) =>
            match.value.toLowerCase().includes(searchTerm.toLowerCase())
          );
        };

        // Check for exact match in both items
        const exactMatchA = hasExactMatch(a);
        const exactMatchB = hasExactMatch(b);

        if (exactMatchA && !exactMatchB) {
          return -1;
        } else if (!exactMatchA && exactMatchB) {
          return 1;
        }

        return a.score - b.score;
      },
    };

    const fuse = new Fuse(allCollections, fuseOptions);

    const getChildCollections = (parentId = null) => {
      const children = allCollections.filter((c) => c.parentId === parentId);
      return children.concat(
        children.flatMap((child) => getChildCollections(child.id))
      );
    };

    const childCollections =
      parentId === "my-prompts"
        ? getChildCollections()
        : getChildCollections(parentId);

    let filteredResults = fuse.search(term).map((result) => result.item);
    filteredResults = filteredResults.filter((result) =>
      result.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return childCollections.filter((collection) =>
      filteredResults.some((result) => result.id === collection.id)
    );
  };

  const filterPrompts = (prompts, term, allPrompts, collectionId = null) => {
    console.log(prompts, allPrompts, term, collectionId, "testingjoe4");

    // Merge allPrompts with prompts, removing duplicates
    const mergedPrompts = allPrompts ? [...allPrompts] : [];
    if (prompts) {
      prompts.forEach((prompt) => {
        if (!mergedPrompts.some((p) => p.id === prompt.id)) {
          mergedPrompts.push(prompt);
        }
      });
    }

    if (!term || !mergedPrompts.length) {
      return prompts;
    }

    const fuseOptions = {
      keys: ["name"],
      // Lower threshold means stricter matching, higher means more lenient
      threshold: 0.3, // Changed from dynamic threshold to fixed value
      // Add these new options
      minMatchCharLength: 2, // Match at least 2 characters
      distance: 100, // Allow more characters between matches
      ignoreLocation: true, // Don't consider character location in scoring
      useExtendedSearch: true, // Enable extended search features
      shouldSort: true,
      sortFn: (a, b) => {
        // Helper function to check if any of the matches are exact
        const hasExactMatch = (item) => {
          return item.matches.some((match) =>
            match.value.toLowerCase().includes(searchTerm.toLowerCase())
          );
        };

        // Check for exact match in both items
        const exactMatchA = hasExactMatch(a);
        const exactMatchB = hasExactMatch(b);

        if (exactMatchA && !exactMatchB) {
          return -1;
        } else if (!exactMatchA && exactMatchB) {
          return 1;
        }

        return a.score - b.score;
      },
    };

    const fuse = new Fuse(mergedPrompts, fuseOptions);

    const getChildPrompts = (collectionId) => {
      const directPrompts = mergedPrompts.filter(
        (p) => p.collectionId === collectionId
      );
      const childCollections = allCollections.filter(
        (c) => c.parentId === collectionId
      );
      return directPrompts.concat(
        childCollections.flatMap((child) => getChildPrompts(child.id))
      );
    };

    const childPrompts = getChildPrompts(collectionId);

    let filteredResults = fuse.search(term).map((result) => result.item);

    filteredResults = filteredResults.filter((result) =>
      result.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    console.log(prompts, "testingjoe5");

    // Filter and ensure collectionId is included in each prompt
    // Filter the original 'prompts' array, which contains the 'notes' and 'prompt' fields
    return prompts
      .filter((prompt) =>
        // Check if this prompt's ID is present in the filtered results
        filteredResults.some((result) => {
          return prompt && result.id === prompt.id;
        })
      )
      .map((prompt) => {
        // Ensure the collectionId param is included with each prompt
        if (collectionId && !prompt.collectionId) {
          // Add collectionId if it's provided and not already present
          return { ...prompt, collectionId };
        }
        // Return the prompt object, now including 'notes' and 'prompt' fields
        return prompt;
      });
  };
  const getCollectionsByParentId = (collections = [], parentId) => {
    return collections.filter((collection) => collection.parentId === parentId);
  };

  const getPromptsByCollectionId = (prompts = [], collectionId) => {
    return prompts.filter((prompt) => prompt.collectionId === collectionId);
  };

  let currentCollection = null;
  let collectionsToRender = [];
  let promptsToRender = [];

  console.log(location.pathname, window.location.pathname, "testingjoet");

  // Handle selected curated category if one is selected
  if (selectedCuratedCategory && curatedCollections[selectedCuratedCategory]) {
    // Get collections for the selected category
    const categoryData = curatedCollections[selectedCuratedCategory];

    // Transform the collections object into an array format compatible with the app
    collectionsToRender = Object.entries(categoryData.collections || {}).map(
      ([id, data]) => ({
        id,
        name: data.name,
        icon: data.icon || "IoIosFolder", // Default icon
        color: data.color || "#0066cc", // Default color
        ...data,
        // Add any other required properties
      })
    );

    // No prompts at this level for curated collections
    promptsToRender = [];
  } else if (
    location.pathname === "/collections" ||
    location.pathname === "/" ||
    location.pathname === "/collections/my-prompts"
  ) {
    const normalizedPath = window.location.pathname.replace(/\/$/, ""); // Remove trailing slash
    console.log(normalizedPath, "testingjoe333");
    const isPrompts = ["/prompts"].includes(normalizedPath);

    const isMyPrompts = ["/prompts/collections/my-prompts"].includes(
      normalizedPath
    );
    const isServices = normalizedPath.includes("services");
    console.log(isPrompts, collections, prompts, normalizedPath, "ttt3");

    if (isMyPrompts) {
      collectionsToRender = getCollectionsByParentId(collections, "my-prompts");
      promptsToRender = getPromptsByCollectionId(prompts, "my-prompts");
    } else if (isPrompts) {
      collectionsToRender = getCollectionsByParentId(
        collections,
        "Lx2VSv07LplHyGPpI7tt"
      );
      promptsToRender = getPromptsByCollectionId(
        prompts,
        "Lx2VSv07LplHyGPpI7tt"
      );
    } else if (isServices) {
      collectionsToRender = getCollectionsByParentId(
        collections,
        "proprompt_store_services"
      );
      promptsToRender = getPromptsByCollectionId(
        prompts,
        "proprompt_store_services"
      );
      console.log(collectionsToRender, promptsToRender, "ttt4");
    } else {
      collectionsToRender = getCollectionsByParentId(collections, null);
      promptsToRender = getPromptsByCollectionId(prompts, null);
    }
  } else if (parentId) {
    currentCollection = collections?.find(
      (collection) => collection.id === parentId
    );
    collectionsToRender = getCollectionsByParentId(collections, parentId);
    promptsToRender = getPromptsByCollectionId(prompts, parentId);
  }

  const filteredCollections = useMemo(() => {
    return filterCollections(
      collectionsToRender,
      searchTerm,
      allCollections,
      parentId
    );
  }, [collectionsToRender, searchTerm, allCollections, parentId]);

  const filteredPrompts = useMemo(() => {
    const fPrompts = filterPrompts(
      promptsToRender,
      searchTerm,
      allPrompts,
      parentId === "my-prompts" ? null : parentId
    );

    return fPrompts;
  }, [promptsToRender, searchTerm, allPrompts, parentId]);

  // Add this new effect for scrolling
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const promptId = urlParams.get("promptId");

    if (promptId && filteredPrompts.length > 0) {
      const promptElement = document.querySelector(
        `[data-rbd-draggable-id="${promptId}"]`
      );
      if (promptElement) {
        promptElement.scrollIntoView({ behavior: "smooth", block: "center" });
        promptElement.classList.add("highlight-prompt");

        setTimeout(() => {
          promptElement.classList.remove("highlight-prompt");
        }, 3000); // 0.5s × 3 iterations = 1.5s total
      }
    }
  }, [filteredPrompts]);

  const handleCopyPrompt = (promptText) => {
    if (!promptText) return;
    // Remove HTML tags and replace them with newlines
    const plainText = promptText.replace(/<\/?[^>]+(>|$)/g, "\n");

    navigator.clipboard.writeText(plainText).then(
      () => {
        MySwal.fire({
          toast: true,
          position: "bottom-left",
          icon: "success",
          title: "Copied to clipboard",
          showConfirmButton: false,
          timer: 3000,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
      },
      (err) => {
        console.error("Could not copy text: ", err);
      }
    );
  };

  // const hexToRgba = (hex, alpha) => {
  //   const r = parseInt(hex.slice(1, 3), 16);
  //   const g = parseInt(hex.slice(3, 5), 16);
  //   const b = parseInt(hex.slice(5, 7), 16);
  //   return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  // };

  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const { source, destination } = result;
    let items = [...filteredCollections];
    let type = "collections";

    if (source.droppableId === "prompts") {
      items = [...filteredPrompts];
      type = "prompts";
    }

    console.log(
      items,
      source,
      "testingchild",
      filteredCollections,
      filteredPrompts
    );
    const [reorderedItem] = items.splice(source.index, 1);
    items.splice(destination.index, 0, reorderedItem);

    if (type === "collections") {
      // setFilteredCollections(items);
    } else {
      // setFilteredPrompts(items);
    }

    const newOrder = items.map((item) => item.id);

    console.log(currentCollection, "testingmin");
    handleUpdateOrder(currentCollection?.id, newOrder, type);
  };

  const handleEdit = (item) => {
    if (item.hasOwnProperty("collectionId")) {
      // Implement edit functionality
      setPromptPopupStatus(true);
      setItemPopupContent(item);
    } else if (item.hasOwnProperty("parentId")) {
      // Implement edit functionality
      setFolderPopupStatus(true);
      setItemPopupContent(item);
    }
  };

  function findPlaceholders(text) {
    const regex = /\{([^}]+)\}/g;
    let placeholders = [];
    let match;

    while ((match = regex.exec(text)) !== null) {
      placeholders.push(match[1]);
    }

    return placeholders;
  }

  const handleItemClick = (item) => {
    if (!item.placeholders || item.placeholders.length === 0) {
      item.placeholders = findPlaceholders(item.prompt);
    }
    if (item.placeholders && item.placeholders.length > 0 && item.prompt) {
      MySwal.fire({
        html: (
          <PromptPopup
            prompt={item.prompt}
            title={item.name}
            note={item.note}
            placeholders={item.placeholders}
            onClose={() => MySwal.close()}
          />
        ),
        showConfirmButton: false,
        width: 800,
        padding: "0px",
        customClass: {
          htmlContainer: "overflow-y-hidden padding-0",
          popup: "overflow-y-hidden",
        },
      });
    } else if (item.prompt) {
      handleCopyPrompt(item.prompt);
    } else if (item.id) {
      // Clear the selected curated category when navigating to a collection
      if (selectedCuratedCategory) {
        selectCuratedCategory(null);
      }

      console.log(item, "item searchhh");
      // This handles navigation to a collection
      // navigate(`/collections/${item.id}`);
    }
  };

  // --- Selection Logic ---
  const showSelectionUI = user?.role === "admin" && origin === "master";
  const allVisibleItemIds = useMemo(() => {
    if (!showSelectionUI) return [];
    const collectionIds = filteredCollections.map((c) => c.id);
    const promptIds = filteredPrompts.map((p) => p.id);
    return [...collectionIds, ...promptIds];
  }, [filteredCollections, filteredPrompts, showSelectionUI]);

  const handleSelectItem = (itemId, isSelected) => {
    setSelectedItems((prevSelected) => {
      const newSelected = new Set(prevSelected);
      if (isSelected) {
        newSelected.add(itemId);
      } else {
        newSelected.delete(itemId);
      }
      return newSelected;
    });
  };

  const handleSelectAll = (selectAll) => {
    if (selectAll) {
      setSelectedItems(new Set(allVisibleItemIds));
    } else {
      setSelectedItems(new Set());
    }
  };

  const handleCopyPaste = () => {
    if (selectedItems.size === 0) return;
    setCopyPasteDialogOpen(true);
  };

  const handleConfirmCopyPaste = async (
    itemsToCopy,
    destinationCollectionId
  ) => {
    console.log(
      "Confirming copy/paste in Main:",
      itemsToCopy,
      destinationCollectionId
    );
    try {
      const result = await handleBulkDuplicate(
        itemsToCopy,
        destinationCollectionId
      );
      console.log("Bulk duplication result:", result);

      // Show success message (potentially including errors reported by backend)
      let message = result.message || `${itemsToCopy.length} items processed.`;
      if (result.errors && result.errors.length > 0) {
        message += ` Errors: ${result.errors.join(", ")}`;
        MySwal.fire("Partial Success", message, "warning");
      } else {
        MySwal.fire("Success", message, "success");
      }
      // Optionally: Fetch updated data for the target collection or refresh all data
      // For now, just clear selection. User can navigate to see changes.
      setSelectedItems(new Set());
    } catch (error) {
      console.error("Failed to duplicate items:", error);
      MySwal.fire(
        "Error",
        `Failed to duplicate items: ${error.message || "Unknown error"}`,
        "error"
      );
    }
  };
  // --- End Selection Logic ---

  const renderItems = (items, type) => {
    // Sort items to put starred items first if user.starredItems exists
    const sortedItems = user?.starredItems
      ? [...items].sort((a, b) => {
          const aIsStarred = user.starredItems.includes(a.id) ? 1 : 0;
          const bIsStarred = user.starredItems.includes(b.id) ? 1 : 0;
          return bIsStarred - aIsStarred;
        })
      : items;

    // Determine if checkbox should be shown for this type and globally
    const shouldShowCheckbox =
      showSelectionUI && (type === "collection" || type === "prompt");

    return sortedItems.map((item, index) => (
      <Draggable
        key={item.id}
        draggableId={item.id}
        index={index}
        isDragDisabled={!user || user.role !== "admin"}
      >
        {(provided) => (
          <Item
            searchTerm={searchTerm}
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            provided={provided}
            item={item}
            type={type}
            onEdit={handleEdit}
            onClick={handleItemClick}
            user={user}
            showCheckbox={shouldShowCheckbox}
            isSelected={selectedItems.has(item.id)}
            onSelect={handleSelectItem}
          />
        )}
      </Draggable>
    ));
  };

  if (loading || fetchingAdditionalPrompts) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  console.log(props.path, "testingmin2");

  return (
    <div className="main-container">
      <TabNavigation />
      <span className="collection-heading">
        <span className="collection-info">
          <span style={{ display: "flex", alignItems: "start", gap: "5px" }}>
            {" "}
            {location.pathname !== "/" && (
              <span className="back" onClick={() => navigate(-1)}>
                <BackButton />
              </span>
            )}{" "}
            <h2 className="main-title">{currentCollection?.name}</h2>
          </span>
          <p className="main-notes">{currentCollection?.notes}</p>
          {!location.pathname.startsWith("/") &&
            !location.pathname.includes("/collections/my-prompts") && (
              <p className="main-notes">
                {filteredCollections.length > 0
                  ? `Collections: ${filteredCollections.length} `
                  : ""}
                {filteredPrompts.length > 0
                  ? `Prompts: ${filteredPrompts.length}`
                  : ""}
              </p>
            )}
        </span>
      </span>

      {origin === "prompts" &&
        (window.location.pathname === "/prompts/" ||
          window.location.pathname === "/prompts") && (
          // <div
          //   style={{
          //     position: "relative",
          //     paddingBottom: "50%",
          //     height: 0,
          //     overflow: "hidden",
          //     maxWidth: "100%",
          //     background: "#fff",
          //   }}
          // >
          //   <iframe
          //     style={{
          //       position: "absolute",
          //       top: 0,
          //       left: 0,
          //       width: "100%",
          //       height: "100%",
          //     }}
          //     src="https://www.youtube.com/embed/aMElbsTj84g?rel=0"
          //     title="ProPrompt How To Use"
          //     frameBorder="0"
          //     allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          //     referrerPolicy="strict-origin-when-cross-origin"
          //     allowFullScreen
          //   ></iframe>
          // </div>
          <FilterTags />
        )}

      <SearchBar setSearchTerm={setSearchTerm} />

      {/* Conditionally render SelectionBar */}
      {showSelectionUI && (
        <SelectionBar
          itemCount={allVisibleItemIds.length}
          selectedCount={selectedItems.size}
          onSelectAll={handleSelectAll}
          onCopyPaste={handleCopyPaste}
          isAllSelected={
            allVisibleItemIds.length > 0 &&
            selectedItems.size === allVisibleItemIds.length
          }
        />
      )}

      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="collections">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {renderItems(filteredCollections, "collection").map(
                (item, index) => (
                  <Draggable
                    key={item.key}
                    draggableId={item.key}
                    index={index}
                    isDragDisabled={!user || user.role !== "admin"} // Prevent dragging if user is not admin
                  >
                    {(provided) => item.props.children(provided)}
                  </Draggable>
                )
              )}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
        <Droppable droppableId="prompts">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {currentCollection && filteredPrompts.length === 0 ? (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    mt: 2,
                  }}
                >
                  {!fetchingAdditionalPrompts && (
                    <Alert
                      severity="info"
                      sx={{
                        width: "80%",
                        margin: "auto",
                      }}
                    >
                      No prompts found
                    </Alert>
                  )}
                </Box>
              ) : (
                renderItems(filteredPrompts, "prompt")
              )}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      {user?.role === "admin" && origin === "master" && (
        <>
          {" "}
          <AddPrompt />
          <AddFolder />
          <PurgeCache />
        </>
      )}
      {promptPopupStatus && (
        <EditPrompt
          openStatus={promptPopupStatus}
          promptPopupContent={itemPopupContent}
          handleClose={() => setPromptPopupStatus(false)}
        />
      )}
      {folderPopupStatus && (
        <EditFolder
          openStatus={folderPopupStatus}
          promptPopupContent={itemPopupContent}
          handleClose={() => setFolderPopupStatus(false)}
        />
      )}
      <CopyPasteDialog
        open={copyPasteDialogOpen}
        onClose={() => setCopyPasteDialogOpen(false)}
        itemsToCopy={Array.from(selectedItems)}
        allCollections={allCollections || []}
        onConfirm={handleConfirmCopyPaste}
      />
    </div>
  );
};

export default Main;
