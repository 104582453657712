// Your web app's Firebase configuration

// src/firebase.js

import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyCfhgmPL2NQLT6X0m_mud94LAWudDCFvWw",
  authDomain: "proprompt-store.firebaseapp.com",
  projectId: "proprompt-store",
  storageBucket: "proprompt-store.appspot.com",
  messagingSenderId: "40442144969",
  appId: "1:40442144969:web:a911744d137d5bfa8cc518",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and get a reference to the service
const auth = getAuth(app);

export { auth, app };
