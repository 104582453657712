import React, { useState, useRef, useEffect } from "react";
import "./DropdownMenu.css";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import { DataContext } from "./DataContext";

const DropdownMenu = ({
  onEdit,
  onDelete,
  onMove,
  onDuplicate,
  type,
  itemId,
  searchTerm,
}) => {
  const { masterParentId } = React.useContext(DataContext);
  const [isOpen, setIsOpen] = useState(false);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const menuRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [menuRef]);

  const toggleMenu = (e) => {
    e.stopPropagation();
    setIsOpen(!isOpen);
  };

  const handleDeleteClick = () => {
    setDeleteConfirmationOpen(true);
  };

  const handleDeleteConfirm = (e) => {
    e.stopPropagation();
    console.log("deletion not working");
    onDelete();
    setDeleteConfirmationOpen(false);
  };

  const handleDeleteCancel = (e) => {
    e.stopPropagation();

    setDeleteConfirmationOpen(false);
  };

  console.log(itemId, masterParentId, "itemId, masterParentId");

  return (
    <div className="dropdown-menu-container" id="dropdown-menu" ref={menuRef}>
      <button className="dropdown-toggle" onClick={toggleMenu}>
        &#x22EE;
      </button>
      {isOpen && (
        <div className="dropdown-menu">
          {!searchTerm && (
            <button
              className="dropdown-item"
              onClick={(e) => {
                e.stopPropagation();
                onEdit();
                setIsOpen(false);
              }}
            >
              Edit
            </button>
          )}
          {itemId !== masterParentId && (
            <button
              className="dropdown-item"
              onClick={(e) => {
                e.stopPropagation();
                handleDeleteClick();
              }}
            >
              Delete
            </button>
          )}
          <button
            className="dropdown-item"
            onClick={(e) => {
              e.stopPropagation();
              onMove();
              setIsOpen(false);
            }}
          >
            Move
          </button>

          <button
            className="dropdown-item"
            onClick={(e) => {
              e.stopPropagation();
              onDuplicate();
              setIsOpen(false);
            }}
          >
            Duplicate
          </button>
        </div>
      )}

      {/* Delete Confirmation Dialog */}
      <Dialog open={deleteConfirmationOpen} onClose={handleDeleteCancel}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          Are you sure you want to delete this item?
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteCancel} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteConfirm} color="primary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DropdownMenu;
