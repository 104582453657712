import React, { useState, useContext } from "react";
import "./TabNavigation.css";
import { NavLink, useLocation, useNavigate } from "react-router-dom"; // useNavigate for navigation
import CreditsPopup from "./CreditsPopup"; // Import the CreditsPopup component
import { UserContext } from "./UserContext";
import { ReactComponent as DollarButton } from "./svg/dollar.svg";

const TabNavigation = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const { user, loading } = useContext(UserContext); // Access loading state
  const location = useLocation(); // Hook to access the current URL
  const navigate = useNavigate(); // useNavigate hook for navigation

  // Assuming creditsLeft comes from the user context, replace this with actual data
  const creditsLeft = user?.collectionLimit || 0;

  const items = [
    { id: "1", name: "Item 1" },
    { id: "2", name: "Item 2" },
    { id: "3", name: "Item 3" },
    { id: "4", name: "Item 4" },
    { id: "5", name: "Item 5" },
    // Add more items as needed
  ];

  const handleCreditsClick = () => {
    if (creditsLeft === 0) {
      handleBuyCredits(); // Redirect if credits are zero
    } else {
      setIsPopupOpen(true); // Open popup if credits are available
    }
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };

  const handleSaveSelectedItems = (selectedItems) => {
    console.log("Selected Items:", selectedItems);
    // Handle saving logic here
  };

  const handleBuyCredits = () => {
    const currentURL = window.location.href; // Get the current URL

    if (!loading && !user) {
      // If not loading and user is not logged in, redirect to /signup with current URL as redirect param
      const signupURL = `https://proprompt.store/signup?redirect=${encodeURIComponent(
        currentURL
      )}`;
      window.open(signupURL, "_blank");
    } else {
      window.open("https://proprompt.store/packages", "_blank");
      console.log("Redirecting to buy credits page...");
    }
  };

  return (
    <div className="tab-navigation">
      <NavLink
        to="/"
        className={`tab ${
          location.pathname.startsWith("/collections") &&
          !location.pathname.startsWith("/collections/my-prompts")
            ? "active-tab"
            : ""
        }`}
        exact // Ensures that the exact path is matched
      >
        EXPLORE PROMPTS
      </NavLink>

      {user && user.role !== "admin" && (
        <NavLink
          to="/collections/my-prompts"
          className="tab"
          activeClassName="active-tab"
        >
          MY PROMPTS
        </NavLink>
      )}

      <div className="credits-section" onClick={handleCreditsClick}>
        <div className="credits-display">
          {creditsLeft > 0
            ? `Credits available: ${creditsLeft}`
            : "Buy Credits"}
        </div>
        <div className="credits-icon">
          <DollarButton />
        </div>
      </div>

      {/* CreditsPopup component */}
      {creditsLeft > 0 && (
        <CreditsPopup
          isOpen={isPopupOpen}
          onClose={handleClosePopup}
          creditsLeft={creditsLeft}
          items={items}
          onSave={handleSaveSelectedItems}
          onBuyCredits={handleBuyCredits}
        />
      )}
    </div>
  );
};

export default TabNavigation;
