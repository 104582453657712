import React from "react";
import "./index.css";
// ... rest of your cod
import { createRoot } from "react-dom/client";
import App from "./App";

export function mountReactApp(element) {
  const root = createRoot(element);
  root.render(<App />);
}

// Attach the function to the window object for global access
if (typeof window !== "undefined") {
  window.mountReactApp = mountReactApp;
}

// If running as a standalone app, mount to the root element
const standaloneElement = document.getElementById("root-react");
if (standaloneElement) {
  mountReactApp(standaloneElement);
}
