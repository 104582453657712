import React from "react";
import "react-tooltip/dist/react-tooltip.css";
import { returnAPI } from "./config";
import "./AddFolder.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const PurgeCache = () => {
  const handlePurgeCache = async () => {
    try {
      toast.info("Purging cache...");
      const apiURL = returnAPI();
      const response = await fetch(`${apiURL}/purge-cloudflare-cache`, {
        method: "POST",
      });
      if (response.ok) {
        toast.success("Cache purged successfully!");
      } else {
        toast.error("Failed to purge cache. Please try again.");
      }
    } catch (error) {
      toast.error("Failed to purge cache. Please try again.");
    }
  };

  return (
    <div>
      <button
        className="purge-cache-button"
        onClick={handlePurgeCache}
        data-tooltip-id="addFolderTooltip"
        data-tooltip-content="Add Folder"
      >
        Purge
      </button>
    </div>
  );
};

export default PurgeCache;
