import React, { useState, useContext, useEffect } from "react";
import "./FilterTags.css";
import { DataContext } from "./DataContext";

const FilterTags = () => {
  const {
    curatedCollections,
    fetchingCuratedCollections,
    selectedCuratedCategory,
    selectCuratedCategory,
  } = useContext(DataContext);
  const [tags, setTags] = useState([]);

  // Update tags when curatedCollections is loaded
  useEffect(() => {
    if (curatedCollections && Object.keys(curatedCollections).length > 0) {
      setTags(Object.keys(curatedCollections));
    }
  }, [curatedCollections]);

  const handleTagClick = (tag) => {
    selectCuratedCategory(tag === selectedCuratedCategory ? null : tag);
  };

  if (fetchingCuratedCollections) {
    return <div className="filter-tags-container">Loading categories...</div>;
  }

  return (
    <div className="filter-tags-container">
      {tags.map((tag) => (
        <button
          key={tag}
          className={`filter-tag ${
            selectedCuratedCategory === tag ? "active" : ""
          } ${tag === "Test Prompts (Free)" ? "free-tag" : ""}`}
          onClick={() => handleTagClick(tag)}
        >
          {tag}
        </button>
      ))}
    </div>
  );
};

export default FilterTags;
