import React from "react";

const Image = ({ src, alt = "image", onClick }) => {
  return (
    <img
      onClick={onClick}
      src={src}
      alt={alt}
      style={{ width: "25px", height: "auto" }}
    />
  );
};

export default Image;
