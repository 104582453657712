import React, { useContext, useState, useEffect, useCallback } from "react";
import { FaPalette } from "react-icons/fa";
import "react-tooltip/dist/react-tooltip.css";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  InputAdornment,
  Box,
  FormControl,
  InputLabel,
  Input,
  FormGroup,
  FormControlLabel,
  Switch,
  IconButton,
  Popover,
  Chip,
} from "@mui/material";
import { IoIosFolder, IoIosFolderOpen } from "react-icons/io";
import {
  MdOutlineFolderCopy,
  MdOutlineDriveFolderUpload,
} from "react-icons/md";
import {
  GrActions,
  GrEmoji,
  GrFan,
  GrProjects,
  GrTarget,
  GrTest,
  GrPieChart,
} from "react-icons/gr";
import { GiAnchor } from "react-icons/gi";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import { DataContext } from "./DataContext"; // Import DataContext

import "./AddPrompt.css";
import { useParams } from "react-router-dom";
import { generateIdFromName } from "./lib";
import Image from "./Image";

const EditPrompt = (props) => {
  const { openStatus, promptPopupContent = null } = props;

  console.log(props, "testingktty");
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [notes, setNotes] = useState("");
  const [quillContent, setQuillContent] = useState("");
  const [showNotes, setShowNotes] = useState(false);
  const [variables, setVariables] = useState(false);
  const [anchorElTitle, setAnchorElTitle] = useState(null);
  const [anchorElPalette, setAnchorElPalette] = useState(null);
  const [paletteColor, setPaletteColor] = useState("#9da19e");
  const [folderIcon, setFolderIcon] = useState(<IoIosFolder />);
  const [uploadedImage, setUploadedImage] = useState(null);

  const { parentId } = useParams();

  const { handleUpdate, handleAdd } = useContext(DataContext); // Access the context

  const icons = [
    { component: <IoIosFolder />, name: "IoIosFolder" },
    { component: <IoIosFolderOpen />, name: "IoIosFolderOpen" },
    { component: <MdOutlineFolderCopy />, name: "MdOutlineFolderCopy" },
    {
      component: <MdOutlineDriveFolderUpload />,
      name: "MdOutlineDriveFolderUpload",
    },
    { component: <GrActions />, name: "GrActions" },
    { component: <GrEmoji />, name: "GrEmoji" },
    { component: <GrFan />, name: "GrFan" },
    { component: <GrProjects />, name: "GrProjects" },
    { component: <GrTarget />, name: "GrTarget" },
    { component: <GrTest />, name: "GrTest" },
    { component: <GrPieChart />, name: "GrPieChart" },
    { component: <GiAnchor />, name: "GiAnchor" },
  ];

  const colors = [
    "#e91e63",
    "#9c27b0",
    "#673ab7",
    "#3f51b5",
    "#2196f3",
    "#03a9f4",
    "#00bcd4",
    "#009688",
    "#4caf50",
    "#8bc34a",
    "#cddc39",
    "#ffeb3b",
    "#ffc107",
    "#ff9800",
    "#ff5722",
    "#795548",
    "#607d8b",
  ];

  const modules = {
    toolbar: [
      [{ size: [] }],
      ["bold", "italic", "underline"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["clean"],
    ],
  };

  const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "list",
    "bullet",
    "link",
  ];
  const handleClose = useCallback(() => {
    setOpen(false);
    if (props.handleClose) {
      props.handleClose();
    }
  }, [props]);

  function findPlaceholders(text) {
    const regex = /\{([^}]+)\}/g;
    let placeholders = [];
    let match;

    while ((match = regex.exec(text)) !== null) {
      placeholders.push(match[1]);
    }

    return placeholders;
  }

  const placeholderExists = findPlaceholders(promptPopupContent?.prompt);

  useEffect(() => {
    if (promptPopupContent) {
      console.log(promptPopupContent.placeholders, "testingkit");
      setTitle(promptPopupContent.name || "");
      if (promptPopupContent.notes) setShowNotes(true);
      setNotes(promptPopupContent.notes || "");
      if (
        promptPopupContent.placeholders?.length > 0 ||
        placeholderExists.length > 0
      )
        setVariables(true);
      setQuillContent(promptPopupContent.prompt || "");
      setPaletteColor(promptPopupContent.color || "#9da19e");
      const icon = icons.find((icon) => icon.name === promptPopupContent.icon);
      setFolderIcon(
        icon ? (
          icon.component
        ) : promptPopupContent?.icon?.startsWith("https://") ? (
          promptPopupContent.icon
        ) : (
          <IoIosFolder />
        )
      );
      setUploadedImage(
        promptPopupContent?.icon?.startsWith("https://") &&
          promptPopupContent?.icon
      );
    }
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (openStatus) setOpen(true);
    else {
      handleClose();
    }
  }, [openStatus, handleClose]);

  const handleSubmitPrompt = async () => {
    const folderIconName = icons.find(
      (icon) => icon.component.type === folderIcon.type
    )?.name;
    const promptData = {
      name: title,
      notes: notes,
      prompt: quillContent,
      showNotes: showNotes,
      placeholders: extractVariables(quillContent),
      icon: uploadedImage || folderIconName,
      color: paletteColor,
      collectionId: parentId || null,
      id: promptPopupContent
        ? promptPopupContent.id
        : generateIdFromName(title),
    };

    try {
      if (promptPopupContent) {
        await handleUpdate(promptData);
      } else {
        await handleAdd(promptData);
      }
      handleClose();
    } catch (error) {
      console.error("Error saving prompt:", error);
    }
  };

  const handlePaletteTitleClick = (event) => {
    setAnchorElTitle(event.currentTarget);
  };

  const handlePaletteClick = (event) => {
    setAnchorElPalette(event.currentTarget);
  };

  const handleIconSelect = (icon) => {
    setFolderIcon(icon.component);
    setAnchorElTitle(null);
  };

  const handleColorSelect = (color) => {
    setPaletteColor(color);
    setAnchorElPalette(null);
  };

  const extractVariables = (text) => {
    const regex = /\{([^}]+)\}/g;
    let match;
    const variables = [];
    while ((match = regex.exec(text)) !== null) {
      variables.push(match[1]);
    }
    return variables;
  };

  const handleImageUpload = async (event) => {
    const files = event.target.files;
    if (files.length > 0) {
      const file = files[0];
      const formData = new FormData();
      formData.append("file", file);
      formData.append("upload_preset", "tiwecp6e"); // Use environment variables

      try {
        const response = await fetch(
          `https://api.cloudinary.com/v1_1/Lensbird/image/upload`,
          {
            method: "POST",
            body: formData,
          }
        );
        if (!response.ok) {
          throw new Error("Failed to upload image");
        }
        const data = await response.json();
        setUploadedImage(data.secure_url);

        setFolderIcon(data.secure_url);
        setAnchorElTitle(null);
      } catch (error) {
        console.error("Error uploading image:", error);
      }
    }
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            width: "700px",
            maxWidth: "none",
          },
        }}
      >
        <DialogTitle>
          {promptPopupContent ? "Edit Prompt" : "Create Prompt"}
        </DialogTitle>
        <DialogContent>
          <Box sx={{ "& > :not(style)": { m: 1 } }}>
            <FormControl variant="standard" fullWidth>
              <InputLabel htmlFor="input-with-icon-adornment">
                Prompt Title
              </InputLabel>
              <Input
                id="input-with-icon-adornment"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                startAdornment={
                  <InputAdornment position="start">
                    {typeof folderIcon === "string" &&
                    folderIcon?.startsWith("https://") ? (
                      <Image
                        onClick={handlePaletteTitleClick}
                        src={folderIcon}
                      />
                    ) : (
                      <IconButton
                        onClick={handlePaletteTitleClick}
                        size="small"
                      >
                        {folderIcon}
                      </IconButton>
                    )}
                  </InputAdornment>
                }
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton onClick={handlePaletteClick} size="small">
                      <FaPalette
                        style={{ color: paletteColor, fontSize: "20px" }}
                      />
                    </IconButton>
                  </InputAdornment>
                }
              />
              <Popover
                open={Boolean(anchorElTitle)}
                anchorEl={anchorElTitle}
                onClose={() => setAnchorElTitle(null)}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
              >
                <Box sx={{ display: "flex", justifyContent: "center", mt: 3 }}>
                  <Button variant="contained" component="label">
                    Upload Image
                    <input type="file" hidden onChange={handleImageUpload} />
                  </Button>
                </Box>
                <Box sx={{ display: "flex", flexWrap: "wrap", p: 1, gap: 0 }}>
                  {icons.map((icon, index) => (
                    <IconButton
                      key={index}
                      onClick={() => handleIconSelect(icon)}
                      style={{
                        margin: "4px",
                        borderRadius: "0%",
                        flex: "0 0 15%",
                      }}
                    >
                      {icon.component}
                    </IconButton>
                  ))}
                </Box>
              </Popover>
              <Popover
                open={Boolean(anchorElPalette)}
                anchorEl={anchorElPalette}
                onClose={() => setAnchorElPalette(null)}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
              >
                <Box sx={{ display: "flex", p: 1 }}>
                  {colors.map((color) => (
                    <IconButton
                      key={color}
                      onClick={() => handleColorSelect(color)}
                      style={{
                        backgroundColor: color,
                        margin: "0 2px",
                        borderRadius: "50%",
                      }}
                    />
                  ))}
                </Box>
              </Popover>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      checked={variables}
                      onChange={(e) => setVariables(e.target.checked)}
                    />
                  }
                  label="Variables"
                />
                <FormControlLabel
                  control={
                    <Switch
                      checked={showNotes}
                      onChange={(e) => setShowNotes(e.target.checked)}
                    />
                  }
                  label="Notes"
                />
              </FormGroup>
            </FormControl>
            {showNotes && (
              <TextField
                margin="dense"
                id="notes"
                label="Notes"
                type="text"
                fullWidth
                variant="standard"
                multiline
                rows={2}
                value={notes}
                onChange={(e) => setNotes(e.target.value)}
              />
            )}
            <ReactQuill
              theme="snow"
              value={quillContent}
              onChange={setQuillContent}
              modules={modules}
              formats={formats}
              style={{ height: "150px", marginTop: "10px" }}
            />
            {variables && (
              <div className="variables-section" style={{ marginTop: "70px" }}>
                <h3>Variables</h3>
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
                  {extractVariables(quillContent).map((variable, index) => (
                    <Chip
                      key={index}
                      label={variable}
                      variant="outlined"
                      style={{ margin: "2px" }}
                    />
                  ))}
                </Box>
              </div>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSubmitPrompt}>Save</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default EditPrompt;
