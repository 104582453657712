import React, { useMemo, useState, useEffect } from "react";
import "./SearchBar.css";

// Debounce function
function debounce(func, delay) {
  let timeout;
  return function (...args) {
    clearTimeout(timeout);
    timeout = setTimeout(() => func(...args), delay);
  };
}

const SearchBar = ({ setSearchTerm }) => {
  const [inputValue, setInputValue] = useState("");

  // Use useMemo to create a debounced function for setSearchTerm
  const debouncedSetSearchTerm = useMemo(
    () =>
      debounce((value) => {
        if (value.length > 1) {
          setSearchTerm(value);
        } else {
          setSearchTerm("");
        }
      }, 300),
    [setSearchTerm]
  );

  useEffect(() => {
    //when it mounts, set the search term to empty
    setSearchTerm("");
  }, [setSearchTerm]);
  const handleChange = (event) => {
    const value = event.target.value;
    setInputValue(value);
    debouncedSetSearchTerm(value);
  };

  return (
    <div className="search-bar">
      <input
        type="text"
        placeholder="Search"
        value={inputValue}
        onChange={handleChange}
      />
    </div>
  );
};

export default SearchBar;
