import React, { useState, useContext, useEffect, useMemo } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Autocomplete,
  TextField,
  FormControl,
  Paper,
  Typography,
  CircularProgress,
} from "@mui/material";
import { createFilterOptions } from "@mui/material/Autocomplete";
import { styled } from "@mui/system";
import { DataContext } from "./DataContext"; // Assuming you might need context later

const StyledPaper = styled(Paper)({
  maxHeight: 300, // Set max height for the dropdown
});

const CopyPasteDialog = ({
  open,
  onClose,
  itemsToCopy,
  allCollections,
  onConfirm,
}) => {
  const [selectedCollection, setSelectedCollection] = useState(null);
  const [loading, setLoading] = useState(false);
  // const { handleUpdate } = useContext(DataContext); // Get update function if needed

  useEffect(() => {
    let timer;
    if (open) {
      setLoading(true);
      timer = setTimeout(() => {
        setLoading(false);
      }, 100);
    } else {
      setLoading(false);
      setSelectedCollection(null);
    }
    return () => clearTimeout(timer);
  }, [open]);

  const handleCollectionChange = (event, newValue) => {
    setSelectedCollection(newValue);
  };

  const handleConfirm = () => {
    if (!selectedCollection) return; // Maybe show an error?

    // TODO: Implement the actual copy/paste logic here
    // This would likely involve iterating through itemsToCopy
    // and calling an update/create function for each.
    console.log(
      `Copying/Pasting ${itemsToCopy.length} items to collection:`,
      selectedCollection
    );

    // Example: Call a passed-in confirm handler
    onConfirm(itemsToCopy, selectedCollection.id);

    onClose(); // Close the dialog after confirmation
    setSelectedCollection(null); // Reset selection
  };

  const collectionOptions = useMemo(
    () => [...allCollections, { name: "My Prompts (Root)", id: "my-prompts" }],
    [allCollections]
  );

  // Configure filter options for better search accuracy
  const filterOptions = createFilterOptions({
    matchFrom: "any", // Match anywhere in the string
    ignoreCase: true, // Make search case-insensitive
    stringify: (option) => option.name || "", // Ensure we stringify based on name
  });

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm" // Smaller dialog might be suitable
      fullWidth
    >
      <DialogTitle>Copy/Paste Items</DialogTitle>
      <DialogContent sx={{ overflowY: "visible" }}>
        {" "}
        {/* Allow dropdown to overflow */}
        <Typography variant="body1" sx={{ mb: 2 }}>
          Select the destination collection for the {itemsToCopy.length}{" "}
          selected item(s):
        </Typography>
        <FormControl fullWidth>
          <Autocomplete
            options={collectionOptions}
            filterOptions={filterOptions}
            loading={loading}
            loadingText="Loading collections..."
            getOptionLabel={(option) => option.name || "Unknown Collection"}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select Destination Collection"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {loading ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
              />
            )}
            PaperComponent={StyledPaper}
            value={selectedCollection}
            onChange={handleCollectionChange}
            isOptionEqualToValue={(option, value) => option.id === value.id}
          />
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button
          onClick={handleConfirm}
          color="primary"
          disabled={!selectedCollection}
        >
          Confirm Copy/Paste
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CopyPasteDialog;
