import React, { useState, useContext } from "react";
import "./TabNavigation.css";
import { NavLink, useLocation, useNavigate } from "react-router-dom"; // useNavigate for navigation
import CreditsPopup from "./CreditsPopup"; // Import the CreditsPopup component
import { UserContext } from "./UserContext";
import { ReactComponent as DollarButton } from "./svg/dollar.svg";
import { DataContext } from "./DataContext";
import { IoMdSettings } from "react-icons/io"; // Add this import

const TabNavigation = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const { user, loading } = useContext(UserContext); // Access loading state
  const location = useLocation(); // Hook to access the current URL
  const navigate = useNavigate(); // useNavigate hook for navigation
  const { origin } = useContext(DataContext);

  const [selectedCategory, setSelectedCategory] = useState("prompts"); // Add this state

  console.log(origin, "origin");
  // Assuming creditsLeft comes from the user context, replace this with actual data
  const creditsLeft = user?.collectionLimit || 0;

  const items = [
    { id: "1", name: "Item 1" },
    { id: "2", name: "Item 2" },
    { id: "3", name: "Item 3" },
    { id: "4", name: "Item 4" },
    { id: "5", name: "Item 5" },
    // Add more items as needed
  ];

  const handleCreditsClick = () => {
    if (
      user.plan === "Lifetime Subscription" ||
      user.plan === "Yearly Subscription"
    ) {
      window.location.href = "/settings/billing"; // Use window.location.href for base route redirect

      // Do nothing for subscription users - skip popup
    } else if (creditsLeft === 0) {
      handleBuyCredits(); // Redirect if credits are zero
    } else {
      setIsPopupOpen(true); // Open popup for remaining cases
    }
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };

  const handleSaveSelectedItems = (selectedItems) => {
    console.log("Selected Items:", selectedItems);
    // Handle saving logic here
  };

  const handleBuyCredits = () => {
    const currentURL = window.location.href; // Get the current URL

    if (!loading && !user) {
      // If not loading and user is not logged in, redirect to /signup with current URL as redirect param
      const signupURL = `https://proprompt.store/signup?redirect=${encodeURIComponent(
        currentURL
      )}`;
      window.open(signupURL, "_blank");
    } else {
      window.open("https://proprompt.store/packages", "_blank");
      console.log("Redirecting to buy credits page...");
    }
  };

  console.log(location.pathname, "locationpathhh");
  return (
    <div className="tab-navigation">
      <div className="nav-links">
        <a
          href="/"
          className={`tab ${
            (location.pathname === "/" ||
              location.pathname.startsWith("/collections")) &&
            !location.pathname.startsWith("/collections/my-prompts") &&
            !location.pathname.startsWith("/collections/favorites") &&
            new URLSearchParams(location.search).get("src") !== "my-prompts"
              ? "active-tab"
              : ""
          }`}
          onClick={(e) => {
            e.preventDefault();
            window.location.href = "/prompts";
          }}
        >
          EXPLORE PROMPTS
        </a>

        {user && (
          <a
            href="/prompts/collections/my-prompts"
            className={`tab ${
              location.pathname?.includes("/my-prompts") ||
              new URLSearchParams(location.search).get("src") === "my-prompts"
                ? "active-tab"
                : ""
            }`}
            onClick={(e) => {
              e.preventDefault();
              window.location.href = "/prompts/collections/my-prompts";
            }}
          >
            MY PROMPTS
          </a>
        )}
        {/* create a module for favorites  */}
        {user && (
          <a
            href="/prompts/collections/favorites"
            className={`tab ${
              location.pathname?.includes("/favorites") ? "active-tab" : ""
            }`}
            onClick={(e) => {
              e.preventDefault();
              window.location.href = "/prompts/collections/favorites";
            }}
          >
            FAVORITES
          </a>
        )}
      </div>

      {origin !== "master" && (
        <div className="credits-section" onClick={handleCreditsClick}>
          <div className="credits-display">
            {user?.plan === "Yearly Subscription" ||
            user?.plan === "Lifetime Subscription"
              ? "Subscription Active"
              : creditsLeft > 0
              ? `Credits available: ${creditsLeft}`
              : "Buy Credits"}
          </div>
          <div className="credits-icon"></div>
        </div>
      )}

      {/* CreditsPopup component */}
      {creditsLeft > 0 && (
        <CreditsPopup
          isOpen={isPopupOpen}
          onClose={handleClosePopup}
          creditsLeft={creditsLeft}
          items={items}
          onSave={handleSaveSelectedItems}
          onBuyCredits={handleBuyCredits}
        />
      )}
    </div>
  );
};

export default TabNavigation;
