import React, { useState, useEffect } from "react";
import { Button, Snackbar } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import "./PromptPopup.css"; // Your custom CSS file
import "quill/dist/quill.snow.css"; // Import Quill's CSS
import ReactMarkdown from "react-markdown";

const PromptPopup = ({ prompt, note, placeholders }) => {
  const toTitleCase = (str) => {
    return str
      .toLowerCase()
      .split(/(\s*\/\s*)/) // Split by slashes, keeping the slashes and any surrounding spaces
      .map((segment) => {
        return segment
          .split(" ")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" ");
      })
      .join(""); // Join the segments back together
  };
  const [inputs, setInputs] = useState(
    placeholders.map((p) => ({
      placeholder: toTitleCase(p),
      value: "",
      width: 0,
    }))
  );

  const [copySuccess, setCopySuccess] = useState(false);

  useEffect(() => {
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");

    // Use the same font family and size as your input
    const font = "16px Inter";
    context.font = font;

    const calculateWidth = (text) => {
      const metrics = context.measureText(text);
      return metrics.width + 2; // Adding 2px for 1px padding on left and right
    };

    const newInputs = inputs.map((input) => ({
      ...input,
      width: calculateWidth(input.placeholder),
    }));

    setInputs(newInputs);
  }, []);

  const handleChange = (index, value) => {
    const newInputs = [...inputs];
    newInputs[index].value = value; // Update the correct input's value
    setInputs(newInputs);
  };

  const handleCopy = () => {
    let finalPrompt = prompt;

    inputs.forEach((input) => {
      const escapedPlaceholder = input.placeholder.replace(
        /[.*+?^${}()|[\]\\]/g,
        "\\$&"
      ); // Escape special characters
      const regex = new RegExp(`\\{\\s*${escapedPlaceholder}\\s*\\}`, "gi"); // Global, case-insensitive, and trim spaces
      finalPrompt = finalPrompt.replace(regex, (match, offset) => {
        const replacedText = input.value.trim();

        // Check the next character after the placeholder to decide if a space should be added
        const nextChar = finalPrompt.charAt(offset + match.length);

        // If the next character is not a space, comma, or period, add a space
        if (nextChar && ![" ", ",", "."].includes(nextChar)) {
          return `${replacedText} `;
        }
        return replacedText; // No need to add a space
      });
    });

    // Remove any remaining placeholders that weren't replaced (optional)
    finalPrompt = finalPrompt.replace(/\{.*?\}/g, "");

    // Remove HTML tags from the final prompt
    finalPrompt = finalPrompt.replace(/<\/?[^>]+(>|$)/g, "");

    navigator.clipboard.writeText(finalPrompt).then(() => {
      setCopySuccess(true);
    });
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setCopySuccess(false);
  };

  const renderPrompt = () => {
    return prompt.split(/(\{.*?\})/).map((part, index) => {
      const match = part.match(/\{(.*?)\}/);
      if (match) {
        const placeholder = match[1];
        const input = inputs.find(
          (input) => input.placeholder === toTitleCase(placeholder)
        );

        return (
          <input
            key={index}
            type="text"
            value={input ? input.value : ""}
            onChange={(e) =>
              handleChange(inputs.indexOf(input), e.target.value)
            }
            placeholder={toTitleCase(placeholder)}
            className="inline-input"
            style={{
              width: `${input ? input.width : 0}px`,
              fontFamily: "Inter", // Ensure the input uses the Inter font
              fontSize: "16px",
            }}
            fullWidth
            inputProps={{ "aria-label": placeholder }}
          />
        );
      } else {
        return (
          <span
            className="ql-editor"
            key={index}
            dangerouslySetInnerHTML={{ __html: part }}
          />
        );
      }
    });
  };

  return (
    <div
      className="prompt-popup"
      onClick={(e) => e.stopPropagation()} // Stop event propagation
    >
      <div className="prompt-text">{renderPrompt()}</div>
      <Button
        onClick={handleCopy}
        variant="contained"
        color="primary"
        style={{ fontSize: "16px", padding: "10px 20px", marginTop: "20px" }}
      >
        COPY TO CLIPBOARD
      </Button>

      <Snackbar
        open={copySuccess}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        disableWindowBlurListener // Disables the automatic close on window blur
      >
        <MuiAlert severity="success" sx={{ width: "100%" }}>
          Copied to clipboard
        </MuiAlert>
      </Snackbar>
    </div>
  );
};

export default PromptPopup;
