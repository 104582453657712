import React from "react";
import { Box, Checkbox, Typography, Button } from "@mui/material";

const SelectionBar = ({
  itemCount,
  selectedCount,
  onSelectAll,
  onCopyPaste, // Placeholder for future logic
  isAllSelected,
}) => {
  if (itemCount === 0) {
    return null; // Don't render if there are no items
  }

  const handleSelectAllChange = (event) => {
    onSelectAll(event.target.checked);
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "8px 16px",
        borderBottom: "1px solid #e0e0e0",
        backgroundColor: "#f9f9f9", // Light background for the bar
        height: "40px", // Fixed height
        marginBottom: "10px", // Add some space below the bar
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Checkbox
          checked={isAllSelected}
          indeterminate={selectedCount > 0 && selectedCount < itemCount}
          onChange={handleSelectAllChange}
          size="small"
          sx={{ padding: "0 8px 0 0" }} // Adjust padding
        />
        <Typography variant="body2" sx={{ marginLeft: "4px" }}>
          {selectedCount > 0 ? `${selectedCount} Selected` : "Select All"}
        </Typography>
      </Box>
      <Button
        variant="text"
        size="small"
        onClick={onCopyPaste} // Attach the handler
        disabled={selectedCount === 0} // Disable if nothing is selected
        sx={{ textTransform: "none" }} // Prevent uppercase text
      >
        Copy & Paste
      </Button>
    </Box>
  );
};

export default SelectionBar;
