import React, { useState } from "react";
import { FaPen } from "react-icons/fa";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";

import EditPrompt from "./EditPrompt";

const AddPrompt = () => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <button
        className="add-prompt-button"
        onClick={handleClickOpen}
        data-tooltip-id="addPromptTooltip"
        data-tooltip-content="Add Prompt"
      >
        <FaPen />
      </button>
      <Tooltip id="addPromptTooltip" place="top" effect="solid" />

      <EditPrompt openStatus={open} handleClose={handleClose} />
    </div>
  );
};

export default AddPrompt;
