import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "./UserContext";
import { returnAPI } from "./config";
import "./UsersPage.css";

const UsersPage = () => {
  const { user, token } = useContext(UserContext);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  // Add state for user editing
  const [selectedUser, setSelectedUser] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editForm, setEditForm] = useState({
    name: "",
    displayName: "",
    email: "",
    role: "",
    plan: "",
    collectionLimit: "",
  });
  const [updateMessage, setUpdateMessage] = useState({ type: "", text: "" });

  // Pagination state
  const [pagination, setPagination] = useState({
    totalUsers: 0,
    totalPages: 0,
    currentPage: 1,
    pageSize: 10,
    hasNextPage: false,
    hasPreviousPage: false,
  });

  // Filtering state
  const [filters, setFilters] = useState({
    email: "",
    role: "",
  });

  // Sorting state
  const [sorting, setSorting] = useState({
    field: "createdAt",
    order: "desc",
  });

  // State for filter inputs
  const [searchEmail, setSearchEmail] = useState("");
  const [roleFilter, setRoleFilter] = useState("");

  const [exporting, setExporting] = useState(false);

  useEffect(() => {
    if (token) {
      fetchUsers();
    }
  }, [token, pagination.currentPage, sorting.field, sorting.order, filters]);

  const fetchUsers = async () => {
    try {
      setLoading(true);

      // Build query params
      const queryParams = new URLSearchParams({
        page: pagination.currentPage,
        limit: pagination.pageSize,
        sortField: sorting.field,
        sortOrder: sorting.order,
      });

      if (filters.email) {
        queryParams.append("email", filters.email);
      }

      if (filters.role) {
        queryParams.append("role", filters.role);
      }

      const response = await fetch(
        `${returnAPI()}/get-users?${queryParams.toString()}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error(`Failed to fetch users: ${response.statusText}`);
      }

      console.log(response, "testing");
      const data = await response.json();

      // Update all states with response data
      setUsers(data.users);
      setPagination(data.pagination);
      setSorting(data.sorting);
      setError(null);
    } catch (err) {
      console.error("Error fetching users:", err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  // Helper function to format Firestore timestamp
  const formatFirestoreTimestamp = (timestamp) => {
    if (!timestamp) return "N/A";

    // Convert Firestore timestamp to JS Date
    const milliseconds =
      timestamp._seconds * 1000 + timestamp._nanoseconds / 1000000;
    const date = new Date(milliseconds);

    // Format the date - could be customized further
    return date.toLocaleDateString() + " " + date.toLocaleTimeString();
  };

  // Helper function to get user name from either displayName or name
  const getUserName = (userItem) => {
    return userItem.displayName !== "Anonymous"
      ? userItem.displayName
      : userItem.name || "Anonymous";
  };

  const handlePageChange = (newPage) => {
    setPagination((prev) => ({
      ...prev,
      currentPage: newPage,
    }));
  };

  const handleSortChange = (field) => {
    // Special case for name/displayName fields
    const actualField =
      field === "name"
        ? // If we're sorting by name, let the backend know we need to check both fields
          "name_or_displayName"
        : field;

    setSorting((prev) => ({
      field: actualField,
      order:
        prev.field === actualField && prev.order === "asc" ? "desc" : "asc",
    }));
  };

  const handleFilterSubmit = (e) => {
    e.preventDefault();
    setFilters({
      email: searchEmail,
      role: roleFilter,
    });

    // Reset to first page when applying new filters
    setPagination((prev) => ({
      ...prev,
      currentPage: 1,
    }));
  };

  const clearFilters = () => {
    setSearchEmail("");
    setRoleFilter("");
    setFilters({
      email: "",
      role: "",
    });
  };

  // Add a function to handle user click
  const handleUserClick = (userItem) => {
    setSelectedUser(userItem);
    setEditForm({
      name: userItem.name || "",
      displayName: userItem.displayName || "",
      email: userItem.email || "",
      role: userItem.role || "user",
      plan: userItem.plan || "Starter Pack",
      collectionLimit: userItem.collectionLimit || "",
    });
    setIsEditing(true);
  };

  // Add a function to close edit form
  const handleCloseEdit = () => {
    setIsEditing(false);
    setSelectedUser(null);
    setUpdateMessage({ type: "", text: "" });
  };

  // Add a function to handle form input changes
  const handleEditFormChange = (e) => {
    const { name, value } = e.target;
    setEditForm({
      ...editForm,
      [name]: value,
    });
  };

  // Add a function to handle user update
  const handleUpdateUser = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      setUpdateMessage({ type: "", text: "" });

      // Only send fields that have changed
      const changes = {};
      if (editForm.name !== selectedUser.name && editForm.name !== "")
        changes.name = editForm.name;
      if (
        editForm.displayName !== selectedUser.displayName &&
        editForm.displayName !== ""
      )
        changes.displayName = editForm.displayName;
      if (editForm.email !== selectedUser.email && editForm.email !== "")
        changes.email = editForm.email;
      if (editForm.role !== selectedUser.role && editForm.role !== "")
        changes.role = editForm.role;
      if (editForm.plan !== selectedUser.plan && editForm.plan !== "")
        changes.plan = editForm.plan;
      if (
        editForm.plan === "Starter Pack" &&
        editForm.collectionLimit !== selectedUser.collectionLimit
      )
        changes.collectionLimit = editForm.collectionLimit;

      // If nothing changed, no need to make the request
      if (Object.keys(changes).length === 0) {
        setUpdateMessage({ type: "info", text: "No changes were made" });
        setLoading(false);
        return;
      }

      const response = await fetch(
        `${returnAPI()}/users/${selectedUser.id || selectedUser.uid}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(changes),
        }
      );

      if (response.ok) {
        const updatedUserData = await response.json();

        // Update the user in the local state
        setUsers(
          users.map((u) =>
            (u.id || u.uid) === (selectedUser.id || selectedUser.uid)
              ? { ...u, ...changes }
              : u
          )
        );

        setUpdateMessage({
          type: "success",
          text: "User updated successfully",
        });

        // Close edit form after a short delay
        setTimeout(() => {
          handleCloseEdit();
          // Refresh the users list to get updated data
          fetchUsers();
        }, 2000);
      } else {
        const errorData = await response.json();
        setUpdateMessage({
          type: "error",
          text: errorData.message || "Failed to update user",
        });
      }
    } catch (error) {
      console.error("Error updating user:", error);
      setUpdateMessage({
        type: "error",
        text: "An error occurred while updating the user",
      });
    } finally {
      setLoading(false);
    }
  };

  // Add this new function to handle CSV export
  const handleExportUsers = async () => {
    try {
      setExporting(true);

      const response = await fetch(`${returnAPI()}/export-users`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error(`Export failed: ${response.statusText}`);
      }

      // Get the filename from the Content-Disposition header if available
      const contentDisposition = response.headers.get("Content-Disposition");
      let filename = "users-export.csv";

      if (contentDisposition) {
        const filenameMatch = contentDisposition.match(/filename="(.+)"/);
        if (filenameMatch && filenameMatch[1]) {
          filename = filenameMatch[1];
        }
      }

      // Get the blob from the response
      const blob = await response.blob();

      // Create a download link and trigger the download
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();

      // Clean up
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } catch (error) {
      console.error("Error exporting users:", error);
      setError("Failed to export users: " + error.message);
    } finally {
      setExporting(false);
    }
  };

  if (loading && users.length === 0) {
    return <div className="users-loading">Loading users...</div>;
  }

  if (error) {
    return <div className="users-error">Error: {error}</div>;
  }

  // Check if we're sorting by name or displayName
  const isNameSortActive =
    sorting.field === "name_or_displayName" ||
    sorting.field === "name" ||
    sorting.field === "displayName";

  return (
    <div className="users-container">
      <div className="users-header">
        <h1>Users</h1>
        <button
          className="export-button"
          onClick={handleExportUsers}
          disabled={exporting}
        >
          {exporting ? "Exporting..." : "Export All Users"}
        </button>
      </div>

      {/* Filter form */}
      <div className="users-filters">
        <form onSubmit={handleFilterSubmit}>
          <div className="filter-row">
            <div className="filter-group">
              <label htmlFor="email-filter">Email</label>
              <input
                id="email-filter"
                type="text"
                placeholder="Search by email"
                value={searchEmail}
                onChange={(e) => setSearchEmail(e.target.value)}
              />
            </div>

            <div className="filter-group">
              <label htmlFor="role-filter">Role</label>
              <select
                id="role-filter"
                value={roleFilter}
                onChange={(e) => setRoleFilter(e.target.value)}
              >
                <option value="">All Roles</option>
                <option value="admin">Admin</option>
                <option value="user">User</option>
              </select>
            </div>

            <div className="filter-actions">
              <button type="submit" className="filter-button">
                Apply Filters
              </button>
              <button
                type="button"
                className="filter-button clear"
                onClick={clearFilters}
              >
                Clear
              </button>
            </div>
          </div>
        </form>
      </div>

      {/* Active filters display */}
      {(filters.email || filters.role) && (
        <div className="active-filters">
          <span>Active filters:</span>
          {filters.email && (
            <div className="filter-tag">
              Email: {filters.email}
              <button
                onClick={() => {
                  setSearchEmail("");
                  setFilters((prev) => ({ ...prev, email: "" }));
                }}
              >
                ×
              </button>
            </div>
          )}
          {filters.role && (
            <div className="filter-tag">
              Role: {filters.role}
              <button
                onClick={() => {
                  setRoleFilter("");
                  setFilters((prev) => ({ ...prev, role: "" }));
                }}
              >
                ×
              </button>
            </div>
          )}
        </div>
      )}

      {/* Edit User Modal */}
      {isEditing && selectedUser && (
        <div className="edit-user-overlay">
          <div className="edit-user-modal">
            <div className="edit-user-header">
              <h2>Edit User</h2>
              <button className="close-button" onClick={handleCloseEdit}>
                ×
              </button>
            </div>

            {updateMessage.text && (
              <div className={`update-message ${updateMessage.type}`}>
                {updateMessage.text}
              </div>
            )}

            <form onSubmit={handleUpdateUser} className="edit-user-form">
              <table className="edit-user-table">
                <tbody>
                  <tr>
                    <th>Name</th>
                    <td>
                      <input
                        type="text"
                        name="name"
                        value={editForm.name}
                        onChange={handleEditFormChange}
                        placeholder="User's name"
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>Display Name</th>
                    <td>
                      <input
                        type="text"
                        name="displayName"
                        value={editForm.displayName}
                        onChange={handleEditFormChange}
                        placeholder="Display name"
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>Email</th>
                    <td>
                      <input
                        type="email"
                        name="email"
                        value={editForm.email}
                        onChange={handleEditFormChange}
                        placeholder="Email address"
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>Role</th>
                    <td>
                      <select
                        name="role"
                        value={editForm.role}
                        onChange={handleEditFormChange}
                      >
                        <option value="user">User</option>
                        <option value="admin">Admin</option>
                      </select>
                    </td>
                  </tr>
                  <tr>
                    <th>Plan</th>
                    <td>
                      <select
                        name="plan"
                        value={editForm.plan}
                        onChange={handleEditFormChange}
                      >
                        <option value="free">Select Plan</option>
                        <option value="Starter Pack">Starter Pack</option>
                        <option value="Fonterra Pack">Fonterra Pack</option>
                        <option value="Yearly Subscription">
                          Yearly Subscription
                        </option>
                        <option value="Lifetime Subscription">
                          Lifetime Subscription
                        </option>
                      </select>
                    </td>
                  </tr>
                  {editForm.plan === "Starter Pack" && (
                    <tr>
                      <th>Credit Limit</th>
                      <td>
                        <input
                          type="number"
                          name="collectionLimit"
                          value={editForm.collectionLimit}
                          onChange={handleEditFormChange}
                          placeholder="Enter credit limit"
                        />
                      </td>
                    </tr>
                  )}
                  <tr>
                    <th>User ID</th>
                    <td className="user-id">
                      {selectedUser.id || selectedUser.uid}
                    </td>
                  </tr>
                  <tr>
                    <th>Created At</th>
                    <td>{formatFirestoreTimestamp(selectedUser.createdAt)}</td>
                  </tr>
                </tbody>
              </table>

              <div className="edit-user-actions">
                <button
                  type="button"
                  className="cancel-button"
                  onClick={handleCloseEdit}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="save-button"
                  disabled={loading}
                >
                  {loading ? "Saving..." : "Save Changes"}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      <div className="users-results-info">
        Showing {users.length} of {pagination.totalUsers} users
      </div>

      <div className="users-table-wrapper">
        <table className="users-table">
          <thead>
            <tr>
              <th
                onClick={() => handleSortChange("name")}
                className={isNameSortActive ? `sorted ${sorting.order}` : ""}
              >
                Name{" "}
                {isNameSortActive && (
                  <span className="sort-arrow">
                    {sorting.order === "asc" ? "↑" : "↓"}
                  </span>
                )}
              </th>
              <th
                onClick={() => handleSortChange("email")}
                className={
                  sorting.field === "email" ? `sorted ${sorting.order}` : ""
                }
              >
                Email{" "}
                {sorting.field === "email" && (
                  <span className="sort-arrow">
                    {sorting.order === "asc" ? "↑" : "↓"}
                  </span>
                )}
              </th>
              <th
                onClick={() => handleSortChange("role")}
                className={
                  sorting.field === "role" ? `sorted ${sorting.order}` : ""
                }
              >
                Role{" "}
                {sorting.field === "role" && (
                  <span className="sort-arrow">
                    {sorting.order === "asc" ? "↑" : "↓"}
                  </span>
                )}
              </th>
              <th
                onClick={() => handleSortChange("plan")}
                className={
                  sorting.field === "plan" ? `sorted ${sorting.order}` : ""
                }
              >
                Plan{" "}
                {sorting.field === "plan" && (
                  <span className="sort-arrow">
                    {sorting.order === "asc" ? "↑" : "↓"}
                  </span>
                )}
              </th>
              <th
                onClick={() => handleSortChange("createdAt")}
                className={
                  sorting.field === "createdAt" ? `sorted ${sorting.order}` : ""
                }
              >
                Created At{" "}
                {sorting.field === "createdAt" && (
                  <span className="sort-arrow">
                    {sorting.order === "asc" ? "↑" : "↓"}
                  </span>
                )}
              </th>
              <th>User ID</th>
            </tr>
          </thead>
          <tbody>
            {users.length === 0 ? (
              <tr>
                <td colSpan="6" className="no-users">
                  No users found
                </td>
              </tr>
            ) : (
              users.map((userItem) => (
                <tr
                  key={userItem.id || userItem.uid}
                  onClick={() => handleUserClick(userItem)}
                  className="user-row"
                >
                  <td>{getUserName(userItem)}</td>
                  <td>{userItem.email}</td>
                  <td>{userItem.role || "user"}</td>
                  <td>
                    <span
                      className={`plan-badge ${
                        userItem.plan === "Yearly Subscription"
                          ? "yearly"
                          : userItem.plan === "Lifetime Subscription"
                          ? "lifetime"
                          : userItem.plan === "Starter Pack"
                          ? "starter"
                          : "free"
                      }`}
                    >
                      {userItem.plan || "Free"}
                    </span>
                  </td>
                  <td>{formatFirestoreTimestamp(userItem.createdAt)}</td>
                  <td className="user-id">{userItem.id || userItem.uid}</td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>

      {/* Pagination controls */}
      {pagination.totalPages > 1 && (
        <div className="pagination-controls">
          <button
            className="pagination-button"
            disabled={!pagination.hasPreviousPage}
            onClick={() => handlePageChange(pagination.currentPage - 1)}
          >
            Previous
          </button>

          <div className="pagination-pages">
            {[...Array(pagination.totalPages).keys()].map((i) => (
              <button
                key={i + 1}
                className={`pagination-page ${
                  pagination.currentPage === i + 1 ? "active" : ""
                }`}
                onClick={() => handlePageChange(i + 1)}
              >
                {i + 1}
              </button>
            ))}
          </div>

          <button
            className="pagination-button"
            disabled={!pagination.hasNextPage}
            onClick={() => handlePageChange(pagination.currentPage + 1)}
          >
            Next
          </button>
        </div>
      )}
    </div>
  );
};

export default UsersPage;
