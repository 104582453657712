export const getTextColor = (hex) => {
  if (!hex) return {};
  const hexToRgb = (hex) => {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);
    return { r, g, b };
  };

  const rgb = hexToRgb(hex);
  const luminance = getLuminance(rgb);
  console.log(rgb, hex, luminance, "color");

  return luminance > 0.279 ? "#000000" : "#ffffff";
};

export const getLuminance = ({ r, g, b }) => {
  const a = [r, g, b].map((v) => {
    v /= 255;
    return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4);
  });
  return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
};

export const generateIdFromName = (name) => {
  return name
    .toLowerCase()
    .replace(/[^a-z0-9]+/g, "_") // Replace spaces and special characters with underscores
    .replace(/^_+|_+$/g, ""); // Remove leading and trailing underscores
};
